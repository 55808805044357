<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card flat class="mx-6">
      <v-card-title>
        登录账号管理
        <AppTooltipBtn
          rounded
          btn-class="ml-10"
          color="primary"
          icon="mdi-account-plus"
          label="创建登录账号"
          tooltip="创建一个新的账号"
          @click="showCredentialEditDialog(true)"
        />
      </v-card-title>
      <v-data-table
        class="mt-4 mb-8"
        :items="credentialList"
        item-key="credentialGuid"
        :headers="tableHeaders"
        no-data-text="无登录账号"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:[`item.roleGuid`]="{ item }">
          <span>{{ getRoleFromRoleList(item.roleGuid) }}</span>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-pencil"
            tooltip="修改账号"
            @click="showCredentialEditDialog(false, item)"
          />
          <AppTooltipBtn
            btn-class="mr-2"
            color="primary"
            icon="mdi-lock-reset"
            tooltip="修改密码"
            @click="showEditPasswordDialog(item)"
          />
          <AppTooltipBtn
            v-if="loggedInCredentialGuid !== item.credentialGuid"
            color="error"
            icon="mdi-delete"
            tooltip="删除账号"
            @click="showCredentialDeleteDialog(item)"
          />
        </template>
      </v-data-table>
    </v-card>
    <AppDialog
      v-model="isShowEditCredentialDialog"
      persistent
      size="small"
      :title="editorDialogTitle"
      color="primary"
      action-text="确定"
      :loading="isDialogLoading"
      @confirm="saveCredentialInfoConfirmed"
      @closed="saveCredentialInfoClosed"
    >
      <v-text-field
        label="显示名称"
        v-model="actionCredential.credentialName"
      ></v-text-field>
      <v-text-field
        label="登录名"
        v-model="actionCredential.loginName"
        :rules="fieldRules.loginName"
      ></v-text-field>
      <v-text-field
        v-if="isCreatingNewCredential"
        type="password"
        label="密码"
        v-model="actionCredential.userNewPwd"
        :rules="fieldRules.userNewPwd"
      ></v-text-field>
      <v-text-field
        label="描述"
        v-model="actionCredential.description"
      ></v-text-field>
      <v-select
        label="角色"
        v-model="actionCredential.roleGuid"
        :items="roleList"
        item-text="name"
        item-value="guid"
        :rules="fieldRules.roleGuid"
      ></v-select>
    </AppDialog>
    <AppDialog
      v-model="isShowChangePasswordDialog"
      persistent
      size="small"
      title="修改密码"
      color="primary"
      action-text="确定修改"
      :loading="isDialogLoading"
      @confirm="saveNewPwdConfirmed"
      @closed="changePasswordClosed"
    >
      <v-text-field
        type="password"
        label="当前密码"
        v-model="userPwd"
      ></v-text-field>
      <v-text-field
        type="password"
        label="新密码"
        v-model="userNewPwd"
        :rules="fieldRules.userNewPwd"
      ></v-text-field>
      <v-text-field
        type="password"
        label="确认新密码"
        v-model="userNewPwdRepeat"
        :rules="fieldRules.userNewPwdRepeat"
      ></v-text-field>
    </AppDialog>
    <AppDialog
      v-model="isShowDeleteCredentialDialog"
      size="small"
      :title="deleteDialogTitle"
      color="red"
      action-text="删除"
      :loading="isDialogLoading"
      @confirm="credentialDeleteConfirmed"
    >
      删除后将无法使用该用户登录！
    </AppDialog>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import AppDialog from "@/components/AppDialog";
import AppTooltipBtn from "@/components/AppTooltipBtn";
import {
  fetchEntityCredentialList,
  createOneCredential,
  updateCredentialInfo,
  changeUserPwd,
  deleteOneCredential
} from "@/api/credential";
import { getAllRoles } from "@/api/user";
import { mapGetters } from "vuex";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox,
    AppDialog,
    AppTooltipBtn
  },

  props: {
    guid: {
      type: String,
      required: true
    },
    entity: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      isDialogLoading: false,
      credentialList: [],
      actionCredential: {
        credentialGuid: "",
        credentialName: "",
        loginName: "",
        userNewPwd: "",
        description: "",
        roleGuid: ""
      },
      roleList: [],
      userPwd: "",
      userNewPwd: "",
      userNewPwdRepeat: "",
      tableHeaders: [
        {
          text: "显示名称",
          value: "credentialName"
        },
        {
          text: "登录名",
          value: "loginName"
        },
        {
          text: "描述",
          value: "description"
        },
        {
          text: "角色",
          value: "roleGuid"
        },
        {
          text: "操作",
          value: "actions",
          sortable: false
        }
      ],
      fieldRules: {
        userNewPwd: [val => (val || "").length >= 6 || "新密码不能少于6位"],
        userNewPwdRepeat: [
          val =>
            (val || "").length < this.userNewPwd.length ||
            val === this.userNewPwd ||
            "两次输入的密码不匹配"
        ],
        loginName: [val => (val || "").length > 0 || "登录名不能为空"],
        roleGuid: [val => (val || "").length > 0 || "角色不能为空"]
      },
      // dialog
      isShowEditCredentialDialog: false,
      isCreatingNewCredential: false,
      isShowDeleteCredentialDialog: false,
      isShowChangePasswordDialog: false,
      errorMsg: ""
    };
  },

  watch: {
    guid(newGuid) {
      return this.getEntityCredentialList(newGuid);
    }
  },

  computed: {
    ...mapGetters({
      loggedInCredentialGuid: "user/credentialGuid"
    }),
    editorDialogTitle() {
      if (this.isCreatingNewCredential) {
        return "新建账号";
      } else {
        return "修改账号";
      }
    },
    deleteDialogTitle() {
      return `确定要删除账号${this.actionCredential.loginName}吗？`;
    }
  },

  methods: {
    showCredentialEditDialog(isNewCred, selectedItem) {
      this.isCreatingNewCredential = isNewCred;
      this.actionCredential = selectedItem || {};
      this.isShowEditCredentialDialog = true;
    },
    showEditPasswordDialog(selectedItem) {
      this.actionCredential = selectedItem || {};
      this.isShowChangePasswordDialog = true;
    },
    showCredentialDeleteDialog(selectedItem) {
      this.actionCredential = selectedItem || {};
      this.isShowDeleteCredentialDialog = true;
    },
    getRoleFromRoleList(roleGuid) {
      if (this.roleList && this.roleList.length) {
        let roleFound = this.roleList.find(r => r.guid === roleGuid);
        if (roleFound) {
          return roleFound.name;
        }
      }
      return "";
    },
    async getEntityCredentialList(entityGuid) {
      try {
        this.isLoading = true;
        this.credentialList = await fetchEntityCredentialList(entityGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async getAllRoleList() {
      try {
        this.isLoading = true;
        this.roleList = await getAllRoles();
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isLoading = false;
    },
    async insertOneCredential() {
      try {
        this.isDialogLoading = true;
        let newCredObj = {
          credentialName: this.actionCredential.credentialName,
          loginName: this.actionCredential.loginName,
          password: this.actionCredential.userNewPwd,
          description: this.actionCredential.description,
          roleGuid: this.actionCredential.roleGuid,
          entity: this.entity,
          entityGuid: this.guid
        };
        let newCredGuid = await createOneCredential(newCredObj);
        this.credentialList.push({
          credentialGuid: newCredGuid,
          credentialName: newCredObj.credentialName,
          entity: newCredObj.entity,
          loginName: newCredObj.loginName,
          roleGuid: newCredObj.roleGuid
        });
        this.isShowEditCredentialDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    async updateOneCredential() {
      try {
        this.isDialogLoading = true;
        await updateCredentialInfo(this.actionCredential.credentialGuid, {
          credentialName: this.actionCredential.credentialName,
          loginName: this.actionCredential.loginName,
          description: this.actionCredential.description,
          roleGuid: this.actionCredential.roleGuid
        });
        this.isShowEditCredentialDialog = false;
      } catch (err) {
        this.errorMsg = err.message;
      }
      this.isDialogLoading = false;
    },
    saveCredentialInfoConfirmed() {
      if (this.actionCredential.loginName && this.actionCredential.roleGuid) {
        if (this.isCreatingNewCredential) {
          return this.insertOneCredential();
        } else {
          return this.updateOneCredential();
        }
      }
    },
    saveCredentialInfoClosed() {
      this.actionCredential = {};
    },
    async saveNewPwdConfirmed() {
      if (this.userPwd && this.userNewPwd && this.userNewPwdRepeat) {
        try {
          if (this.userNewPwd !== this.userNewPwdRepeat) {
            this.errorMsg = "两次输入的新密码不同";
            return;
          }
          this.isDialogLoading = true;
          await changeUserPwd(
            this.actionCredential.credentialGuid,
            this.userPwd,
            this.userNewPwd
          );
          this.isShowChangePasswordDialog = false;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isDialogLoading = false;
      }
    },
    changePasswordClosed() {
      // 重置控件
      this.userPwd = "";
      this.userNewPwd = "";
      this.userNewPwdRepeat = "";
    },
    async credentialDeleteConfirmed() {
      let actionCredGuid = this.actionCredential.credentialGuid;
      if (this.loggedInCredentialGuid !== actionCredGuid) {
        try {
          this.isDialogLoading = true;
          await deleteOneCredential(actionCredGuid);
          let deletingIndex = this.credentialList.findIndex(
            cr => cr.credentialGuid === actionCredGuid
          );
          if (deletingIndex > -1) {
            this.credentialList.splice(deletingIndex, 1);
          }
          this.isShowDeleteCredentialDialog = false;
        } catch (err) {
          this.errorMsg = err.message;
        }
        this.isDialogLoading = false;
      } else {
        this.errorMsg = "不能删除自己的账号";
      }
    }
  },

  created() {
    this.getEntityCredentialList(this.guid);
    this.getAllRoleList();
  }
};
</script>
