import { post } from "@/utils/axios";

export async function initNewReportInfo(userGuid) {
  let response = await post("/reportinfo/InitReportTemplateInfo", {
    guid: userGuid
  });
  return response.guid;
}

export async function fetchReportSignInfo(userEntity, userGuid) {
  var reportSign = await post("/reportinfo/GetReportSignInfo", {
    entityType: userEntity,
    entityGuid: userGuid
  });
  return {
    reportInfoGuid: reportSign.reportInfoGuid,
    reportOrgName: reportSign.reportOrgName,
    reporterCandidates: reportSign.reporterCandidatesJson
      ? JSON.parse(reportSign.reporterCandidatesJson)
      : []
  };
}

export function saveReportSignInfo(
  reportInfoGuid,
  { reportOrgName, reporterCandidates }
) {
  return post("/reportinfo/UpdateReportSignInfo", {
    reportInfoGuid,
    reportOrgName,
    reporterCandidatesJson: JSON.stringify(reporterCandidates)
  });
}

export async function fetchCoverPageBody(userEntity, userGuid) {
  let response = await post("/reportinfo/GetCoverPageBody", {
    entityType: userEntity,
    entityGuid: userGuid
  });
  return response.value;
}

export function saveCoverPageBody(reportInfoGuid, coverPageHtml) {
  return post("/reportinfo/UpdateCoverPageContent", {
    reportInfoGuid,
    coverPageHtml
  });
}
